import { forwardRef, ForwardRefRenderFunction } from 'react';

import { Control, Controller, FieldError } from 'react-hook-form';

import { Input, IInputProps } from '../Input';

interface IControlledInputProps extends Omit<IInputProps, 'error'> {
  control: Control<any, any>;
  name: string;
  error?: FieldError;
}

const BaseControlledInput: ForwardRefRenderFunction<
  HTMLInputElement,
  IControlledInputProps
> = ({ control, name, error, onChange, ...props }, ref) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange: fieldOnChange, value } }) => {
        return (
          <Input
            {...props}
            name={name}
            ref={ref}
            value={value}
            error={error?.message || ''}
            onChange={onChange || fieldOnChange}
          />
        );
      }}
    />
  );
};

export const ControlledInput = forwardRef(BaseControlledInput);
