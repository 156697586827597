import { ButtonHTMLAttributes, ReactNode } from 'react';

import { ThemeColors } from 'styled-components';

import { WithChildren } from '~/shared/types/WithChildren';
import { IMarginProps } from '~/shared/interfaces/IMarginProps';
import { TButtonSize } from './types';

import { Container } from './styles';

import { Tooltip } from '../Tooltip';
import { Loader } from '../Loader';

export interface IButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    IMarginProps {
  isLoading?: boolean;
  backgroundColor?: ThemeColors;
  textColor?: ThemeColors;
  size?: TButtonSize;
  disabledHint?: ReactNode;
  borderColor?: ThemeColors;
}

export const Button: WithChildren<IButtonProps> = ({
  isLoading,
  disabled,
  children,
  backgroundColor = 'primary',
  textColor = 'onPrimary',
  size = 'md',
  borderColor,
  m,
  mt,
  mb,
  ml,
  mr,
  mv,
  mh,
  disabledHint,
  type = 'button',
  ...props
}) => {
  return (
    <Tooltip label={disabled && disabledHint}>
      <div>
        <Container
          {...props}
          disabled={isLoading || disabled}
          backgroundColor={backgroundColor}
          textColor={textColor}
          borderColor={borderColor}
          m={m}
          mt={mt}
          mb={mb}
          mr={mr}
          ml={ml}
          mv={mv}
          mh={mh}
          size={size}
          id="button"
          type={type}
        >
          {isLoading ? <Loader color="onDisabledBackground" /> : children}
        </Container>
      </div>
    </Tooltip>
  );
};
