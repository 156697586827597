import { useCallback, useState } from 'react';

import { useToast } from './useToast';

interface ICopyFnOptions {
  successMessage?: string;
  errorMessage?: string;
}

type CopiedValue = string | null;
type CopyFn = (text: string, options?: ICopyFnOptions) => Promise<void>;

interface IUseCopyToClipboardResult {
  copiedText: CopiedValue;
  copy: CopyFn;
  isSupported: boolean;
}

export function useCopyToClipboard(): IUseCopyToClipboardResult {
  const toast = useToast();

  const [copiedText, setCopiedText] = useState<CopiedValue>(null);

  const isSupported =
    typeof navigator !== 'undefined' && !!navigator?.clipboard;

  const copy: CopyFn = useCallback(
    async (text, options = {} as ICopyFnOptions) => {
      if (!isSupported) return;

      try {
        await navigator.clipboard.writeText(text);

        setCopiedText(text);

        if (options.successMessage) {
          toast.show({ title: options.successMessage, variant: 'success' });
        }
      } catch (error) {
        if (options.errorMessage) {
          toast.show({ title: options.errorMessage, variant: 'error' });
        }

        setCopiedText(null);
      }
    },
    [toast, isSupported]
  );

  return { copiedText, copy, isSupported };
}
