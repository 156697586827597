import { ThemeSpacing, css } from 'styled-components';

export interface IMarginProps {
  m?: ThemeSpacing;
  mh?: ThemeSpacing;
  mv?: ThemeSpacing;
  mb?: ThemeSpacing;
  mt?: ThemeSpacing;
  mr?: ThemeSpacing;
  ml?: ThemeSpacing;
}

export const marginPropsStyles = css<IMarginProps>`
  ${({ theme, m }) =>
    !!m &&
    css`
      margin: ${theme.spacing[m]};
    `}

  ${({ theme, mv }) =>
    !!mv &&
    css`
      margin-top: ${theme.spacing[mv]};
      margin-bottom: ${theme.spacing[mv]};
    `}
    
  ${({ theme, mh }) =>
    !!mh &&
    css`
      margin-left: ${theme.spacing[mh]};
      margin-right: ${theme.spacing[mh]};
    `}

  ${({ theme, mb }) =>
    !!mb &&
    css`
      margin-bottom: ${theme.spacing[mb]};
    `}

  ${({ theme, mt }) =>
    !!mt &&
    css`
      margin-top: ${theme.spacing[mt]};
    `}

  ${({ theme, ml }) =>
    !!ml &&
    css`
      margin-left: ${theme.spacing[ml]};
    `}

  ${({ theme, mr }) =>
    !!mr &&
    css`
      margin-right: ${theme.spacing[mr]};
    `}
`;
