import styled, { ThemeSpacing } from 'styled-components';

interface IContainerProps {
  spacing?: ThemeSpacing;
  minWidth: string;
}

export const Container = styled.div<IContainerProps>`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: ${({ theme, spacing }) => theme.spacing[spacing]};

  > * {
    min-width: ${({ minWidth }) => minWidth};
    flex: 1;
  }

  & + & {
    margin-top: ${({ theme, spacing }) => theme.spacing[spacing]};
  }
`;
